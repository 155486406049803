<template>
  <v-container class="pa-0" fluid>
    <page-title :heading="$t('generic.lang_deliveryArea')"
                :subheading="$t('generic.lang_deliveryArea')"
                :icon="icon"
                show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <DeliveryAreaCreateComponent/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import DeliveryAreaCreateComponent from "@/components/delivery/deliveryarea/DeliveryAreaCreateComponent";

export default {
  components: {
    DeliveryAreaCreateComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-map-marker icon-gradient bg-tempting-azure',
  })
}
</script>
